




import { Component, Vue, Watch } from "vue-property-decorator";
import RibbonItems from "./RibbonItems.vue";
import { EnumOfferPaths } from "../enums/EnumOfferPaths";
import { TranslateResult } from "vue-i18n";

@Component({
  components: {
    RibbonItems
  }
})
export default class OfferDetails extends Vue {
  policeRibbons = [
    {
      direction: "left",
      title: this.$t(
        "offersContent.processingAndJustice.firstBox.header"
      ) as TranslateResult,
      description: this.$t(
        "offersContent.processingAndJustice.firstBox.description"
      ) as TranslateResult,
      image: "offers-details/blood.jpg"
    },
    {
      direction: "right",
      title: this.$t(
        "offersContent.processingAndJustice.secondBox.header"
      ) as TranslateResult,
      description: this.$t(
        "offersContent.processingAndJustice.secondBox.description"
      ) as TranslateResult,
      image: "offers-details/evidence.jpg"
    },
    {
      direction: "left",
      title: this.$t(
        "offersContent.processingAndJustice.thirdBox.header"
      ) as TranslateResult,
      description: this.$t(
        "offersContent.processingAndJustice.thirdBox.description"
      ) as TranslateResult,
      image: "offers-details/fire.jpg"
    },
    {
      direction: "right",
      title: this.$t(
        "offersContent.processingAndJustice.fourthBox.header"
      ) as TranslateResult,
      description: this.$t(
        "offersContent.processingAndJustice.fourthBox.description"
      ) as TranslateResult,
      image: "offers-details/opinion.jpg"
    },
    {
      direction: "left",
      title: this.$t(
        "offersContent.processingAndJustice.fifthBox.header"
      ) as TranslateResult,
      description: this.$t(
        "offersContent.processingAndJustice.fifthBox.description"
      ) as TranslateResult,
      image: "offers-details/delivery.jpg"
    }
  ];

  companyRibbons = [
    {
      direction: "left",
      title: this.$t(
        "offersContent.companies.firstBox.header"
      ) as TranslateResult,
      description: this.$t(
        "offersContent.companies.firstBox.description"
      ) as TranslateResult,
      image: "offers-details/evidence.jpg"
    },
    {
      direction: "right",
      title: this.$t(
        "offersContent.companies.secondBox.header"
      ) as TranslateResult,
      description: this.$t(
        "offersContent.companies.secondBox.description"
      ) as TranslateResult,
      image: "offers-details/methods.jpg"
    },
    {
      direction: "left",
      title: this.$t(
        "offersContent.companies.thirdBox.header"
      ) as TranslateResult,
      description: this.$t(
        "offersContent.companies.thirdBox.description"
      ) as TranslateResult,
      image: "offers-details/helmet.jpg"
    }
  ];

  privateRibbons = [
    {
      direction: "left",
      title: this.$t(
        "offersContent.private.firstBox.header"
      ) as TranslateResult,
      description: this.$t(
        "offersContent.private.firstBox.description"
      ) as TranslateResult,
      image: "offers-details/blood.jpg"
    },
    {
      direction: "right",
      title: this.$t(
        "offersContent.private.secondBox.header"
      ) as TranslateResult,
      description: this.$t(
        "offersContent.private.secondBox.description"
      ) as TranslateResult,
      image: "offers-details/evidence.jpg"
    },
    {
      direction: "left",
      title: this.$t(
        "offersContent.private.thirdBox.header"
      ) as TranslateResult,
      description: this.$t(
        "offersContent.private.thirdBox.description"
      ) as TranslateResult,
      image: "offers-details/item.jpg"
    },
    {
      direction: "right",
      title: this.$t(
        "offersContent.private.fourthBox.header"
      ) as TranslateResult,
      description: this.$t(
        "offersContent.private.fourthBox.description"
      ) as TranslateResult,
      image: "offers-details/annonymous.jpg"
    }
  ];

  @Watch("$store.state.language")
  onPropertyChanged(value: string, oldValue: string) {
    this.policeRibbons = [
      {
        direction: "left",
        title: this.$t(
          "offersContent.processingAndJustice.firstBox.header"
        ) as TranslateResult,
        description: this.$t(
          "offersContent.processingAndJustice.firstBox.description"
        ) as TranslateResult,
        image: "offers-details/blood.jpg"
      },
      {
        direction: "right",
        title: this.$t(
          "offersContent.processingAndJustice.secondBox.header"
        ) as TranslateResult,
        description: this.$t(
          "offersContent.processingAndJustice.secondBox.description"
        ) as TranslateResult,
        image: "offers-details/evidence.jpg"
      },
      {
        direction: "left",
        title: this.$t(
          "offersContent.processingAndJustice.thirdBox.header"
        ) as TranslateResult,
        description: this.$t(
          "offersContent.processingAndJustice.thirdBox.description"
        ) as TranslateResult,
        image: "offers-details/fire.jpg"
      },
      {
        direction: "right",
        title: this.$t(
          "offersContent.processingAndJustice.fourthBox.header"
        ) as TranslateResult,
        description: this.$t(
          "offersContent.processingAndJustice.fourthBox.description"
        ) as TranslateResult,
        image: "offers-details/opinion.jpg"
      },
      {
        direction: "left",
        title: this.$t(
          "offersContent.processingAndJustice.fifthBox.header"
        ) as TranslateResult,
        description: this.$t(
          "offersContent.processingAndJustice.fifthBox.description"
        ) as TranslateResult,
        image: "offers-details/delivery.jpg"
      }
    ];

    this.companyRibbons = [
      {
        direction: "left",
        title: this.$t(
          "offersContent.companies.firstBox.header"
        ) as TranslateResult,
        description: this.$t(
          "offersContent.companies.firstBox.description"
        ) as TranslateResult,
        image: "offers-details/evidence.jpg"
      },
      {
        direction: "right",
        title: this.$t(
          "offersContent.companies.secondBox.header"
        ) as TranslateResult,
        description: this.$t(
          "offersContent.companies.secondBox.description"
        ) as TranslateResult,
        image: "offers-details/methods.jpg"
      },
      {
        direction: "left",
        title: this.$t(
          "offersContent.companies.thirdBox.header"
        ) as TranslateResult,
        description: this.$t(
          "offersContent.companies.thirdBox.description"
        ) as TranslateResult,
        image: "offers-details/helmet.jpg"
      }
    ];

    this.privateRibbons = [
      {
        direction: "left",
        title: this.$t(
          "offersContent.private.firstBox.header"
        ) as TranslateResult,
        description: this.$t(
          "offersContent.private.firstBox.description"
        ) as TranslateResult,
        image: "offers-details/blood.jpg"
      },
      {
        direction: "right",
        title: this.$t(
          "offersContent.private.secondBox.header"
        ) as TranslateResult,
        description: this.$t(
          "offersContent.private.secondBox.description"
        ) as TranslateResult,
        image: "offers-details/evidence.jpg"
      },
      {
        direction: "left",
        title: this.$t(
          "offersContent.private.thirdBox.header"
        ) as TranslateResult,
        description: this.$t(
          "offersContent.private.thirdBox.description"
        ) as TranslateResult,
        image: "offers-details/item.jpg"
      },
      {
        direction: "right",
        title: this.$t(
          "offersContent.private.fourthBox.header"
        ) as TranslateResult,
        description: this.$t(
          "offersContent.private.fourthBox.description"
        ) as TranslateResult,
        image: "offers-details/annonymous.jpg"
      }
    ];
  }

  get getProperRibbons() {
    let properRibbons;
    switch (this.$route.params.offerName) {
      case EnumOfferPaths.POLICE:
        properRibbons = this.policeRibbons;
        break;
      case EnumOfferPaths.COMPANY:
        properRibbons = this.companyRibbons;
        break;
      case EnumOfferPaths.PRIVATE:
        properRibbons = this.privateRibbons;
        break;
    }
    return properRibbons;
  }
}
