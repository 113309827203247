<template>
  <div class="offer-details-page">
    <OfferDetails />
  </div>
</template>

<script>
import OfferDetails from "@/components/OfferDetails.vue";

export default {
  name: "OfferDetailsPage",
  components: {
    OfferDetails,
  },
};
</script>

<style>
.offer-details-page {
  background-color: #e1f2f9;
}
</style>
